import createLogger from './logging';
const { indexlog, indexerror } = createLogger('subscribeToPush');

export const updateUserSettings = async (settingName, newValue, getCookie, parseJwt) => {
  try {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const token = getCookie('jwtToken');
    if (!token) {
      window.location.href = '/';
      return;
    }

    const decodedToken = parseJwt(token);
    if (!decodedToken || !decodedToken.userId) {
      indexerror('Token decoding failed or userId not found');
      return;
    }
    const userId = decodedToken.userId;

    const response = await fetch(`${API_BASE_URL}/user-details/${userId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ settingName, newValue }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to update settings');
    }

    indexlog('User Settings updated successfully. UserId', userId);
  } catch (error) {
    indexerror('Error updating settings:', error);
  }
};
