function createLogger(source) {
    function indexlog(message) {
      const timestamp = new Date().toISOString();
      const logMessage = `${timestamp} [${source}] - ${message}`;
  
      if (navigator.serviceWorker && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({ action: 'log', message: logMessage });
      } else {
        console.log(logMessage);
      }
    }
  
    function indexerror(message) {
      const timestamp = new Date().toISOString();
      const errorMessage = `${timestamp} [${source}] - ${message}`;
  
      if (navigator.serviceWorker && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({ action: 'error', message: errorMessage });
      } else {
        console.error(errorMessage);
      }
    }
  
    return { indexlog, indexerror };
  }
  
  export default createLogger;
  