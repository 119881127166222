import createLogger from '../helpers/logging';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';

const { indexlog, indexerror } = createLogger('subscribeToPush');

const urlBase64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
};
  
const getDeviceDetails = () => {
  const userAgent = navigator.userAgent;
  const platform = navigator.platform;
  const language = navigator.language || navigator.userLanguage;

  return { userAgent, platform, language };
};

// Generate a UUID or another unique identifier for the device
function generateDeviceId() {
  return 'xxxx-xxxx-xxxx-xxxx'.replace(/[x]/g, function() {
    return Math.floor(Math.random() * 16).toString(16);
  });
}

// Check if a device ID exists, otherwise create and store one
function getDeviceId() {
  let deviceId = localStorage.getItem('device_id');
  if (!deviceId) {
    deviceId = generateDeviceId();
    localStorage.setItem('device_id', deviceId);
  }
  return deviceId;
}

function registerForPushNotifications() {
  return new Promise((resolve, reject) => {
    PushNotifications.addListener('registration',
      (token) => {
        resolve(token.value); // Resolve the promise with the token
      }
    );

    PushNotifications.addListener('registrationError',
      (error) => {
        indexlog('Error on push registration: '+error);
        reject(error); // Reject the promise if there's an error
      }
    );

    PushNotifications.register().catch(reject); // Also catch any errors during registration
  });
}
  
export const subscribeToPush = async (user) => {
  if (typeof user === 'undefined' || !user) {
    indexerror('User object missing! This should not happen!');
    return null;
  }
  try {
    const deviceId = getDeviceId();
    if ('serviceWorker' in navigator && 'PushManager' in window && Notification.permission === 'granted') {
      indexlog('Service Worker and Push Manager available, Push notification granted.')
      const registration = await navigator.serviceWorker.ready;
      const existingSubscriptionClient = await registration.pushManager.getSubscription();

      let isSubscribed = false;
      let requiresUpdate = false;
      let existingSubscriptionsDb = user.subscriptionDetails || [];

      if (!Array.isArray(existingSubscriptionsDb)) {
        console.warn('Push subscriptions is not an array! Trying to fix!')
        existingSubscriptionsDb = existingSubscriptionsDb ? [existingSubscriptionsDb] : [];
        requiresUpdate = true;
      }

      if (existingSubscriptionClient && existingSubscriptionsDb.length > 0) {
        indexlog('Found existing push subscription. Checking if this client is already registered.');
        const existingSubscriptionClientJson = existingSubscriptionClient.toJSON();

        // Iterate over each subscription in the database
        for (let i = 0; i < existingSubscriptionsDb.length; i++) {
          if (!existingSubscriptionsDb[i].hasOwnProperty('tokenValue')) {
            const subscription = existingSubscriptionsDb[i];
            
            const matchesEndpoint = subscription.endpoint === existingSubscriptionClientJson.endpoint;
            const matchesKeys = subscription.keys.p256dh === existingSubscriptionClientJson.keys.p256dh &&
                                subscription.keys.auth === existingSubscriptionClientJson.keys.auth;
            const matchesDeviceId = !subscription.deviceId || subscription.deviceId === deviceId;

            if (matchesEndpoint && matchesKeys && matchesDeviceId) {
              isSubscribed = true;
              indexlog('Client already registered. Nothing to do.')
              break;
            }
          }
        }
      }

      if (!existingSubscriptionClient || !isSubscribed || requiresUpdate) {
        indexlog('Subscribing to Push Notifications');
        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array('BD0aQ8oqS_dWftoAZtzsrYJIQPXc2OMZt84o8_FotXSKe2u7di6feR6me5b4jJtIsaP7tEsAO9ifqnBQm2KVVvo'),
        });

        const deviceDetails = getDeviceDetails();
  
        const subscriptionWithDeviceDetails = {
          ...subscription.toJSON(),
          deviceDetails,
          deviceId,
        };

        // Filter out subscriptions with the same deviceId, retain those without a deviceId
        const filteredSubscriptions = existingSubscriptionsDb.filter(sub => !sub.deviceId || sub.deviceId !== deviceId);
        const updatedSubscriptions = [...filteredSubscriptions, subscriptionWithDeviceDetails];
        indexlog('Push subscription successful:', subscriptionWithDeviceDetails);
        return updatedSubscriptions;
      }
      else {
        indexlog('Client already has a valid subscription to push notifications.');
        return existingSubscriptionsDb;
      }
    } 
    else if ('serviceWorker' in navigator && 'PushManager' in window){
      indexlog('Rechecking permissions...');
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        return subscribeToPush(); // Recursively subscribe after permission granted
      } else {
        indexerror('Permission for notifications was denied');
      }
    }
    else if (Capacitor.isNativePlatform()) {
     let permissionStatus = await PushNotifications.checkPermissions();
      if (permissionStatus.receive === 'prompt') {
        permissionStatus = await PushNotifications.requestPermissions();
      }

      if (permissionStatus.receive === 'granted') {
        try {
          let existingSubscriptionsDb = user.subscriptionDetails || [];
          let updatedSubscriptions;
          let tokenValue = await registerForPushNotifications();
          indexlog("Push registration success, token received: "+tokenValue);
          const platform = Capacitor.getPlatform();
  
          // Dup and update check
          const existingIndex = existingSubscriptionsDb.findIndex(sub => 
            sub.deviceId === deviceId);

          if (existingIndex === -1) { // Already exists
            const subscriptionWithDeviceDetails = {
              tokenValue,
              platform,
              deviceId,
            };
            updatedSubscriptions = [...existingSubscriptionsDb,subscriptionWithDeviceDetails];
            indexlog("Adding new subscription...");
          }
          else { // Needs update
            existingSubscriptionsDb[existingIndex].tokenValue = tokenValue;
            indexlog("Updating existing subscription...");
          }
          return updatedSubscriptions;
        } 
        catch (error) {
          indexerror('Error during push notification registration:', error);
        }
      }
      else {
        indexlog('User denied push notifications!');
      }
    }
    else {
      console.warn('Seems like the browser doesn\'t support push notifications!');
    }
  } 
  catch (error) {
    indexerror('Error subscribing to push notifications:', error);
  }
  return null;
};
  