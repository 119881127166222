import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Route } from 'react-router-dom';
import { IonApp, IonPage, IonContent, IonRouterOutlet, IonModal,IonToggle, IonSpinner, IonText, IonIcon, IonButton, IonButtons, IonHeader, IonToolbar, IonTitle, IonList, IonLabel, IonItem, IonNote } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { setupIonicReact } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { fitness, planet, arrowForward, alertOutline, checkmark, contract, paw, partlySunny } from 'ionicons/icons';

import { useUser } from './helpers/UserContext.js';
import { useLoading } from './helpers/LoadingContext.js';
import { useDevice } from './helpers/DeviceContext';
import LoadingEventListener from './helpers/LoadingEventListener';
import useDynamicCssLoad from './helpers/dynamicCssLoad.js';
import IPhoneInstallInstructionsOverlay from './helpers/IPhoneInstallInstructionsOverlay.js';
import IPadInstallInstructionsOverlay from './helpers/IPadInstallInstructionsOverlay.js';
import AndroidInstallInstructionsOverlay from './helpers/AndroidInstallInstructionsOverlay.js';
import IPhoneiPadUnsupportedOverlay from './helpers/IPhoneiPadUnsupportedOverlay.js'
import OfflineOverlay from './helpers/OfflineOverlay.js';
import './i18n';
import LoginForm from './LoginForm';
import MainPage from './MainPage';
import RegistrationForm from './RegistrationForm';
import useLogger from './helpers/useLogger';

import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';

import '@ionic/react/css/core.css';

setupIonicReact();

function App() {
  useDynamicCssLoad();

  const { t } = useTranslation();
  const { user, setUser, checkPushPermission } = useUser();
  const deviceInfo = useDevice();
  const [showIPhoneOverlay, setShowIPhoneOverlay] = useState(false);
  const [showIPadOverlay, setShowIPadOverlay] = useState(false);
  const [showAndroidOverlay, setShowAndroidOverlay] = useState(false);
  const [showIPhoneIpadUnsupportedOverlay, setShowIPhoneIpadUnsupportedOverlay] = useState(false);
  const [showOfflineOverlay, setShowOfflineOverlay] = useState(!navigator.onLine);
  const [showAboutModal, setshowAboutModal] = useState(false);
  const [showHowItWorksModal, setshowHowItWorksModal] = useState(false);
  const [showInstallPrompt, setShowInstallPrompt] = useState(false);
  const { indexlog } = useLogger('App');
  const deferredPromptRef = useRef(null);
  const runningNative = Capacitor.isNativePlatform();
  const runningWeb = !Capacitor.isNativePlatform();

  // Listeners for handling native push notifications
  useEffect(() => {
    if (runningNative) {
      PushNotifications.addListener('pushNotificationReceived',
        (notification) => {
          console.log('Push received: ' + JSON.stringify(notification));
        }
      );
  
      PushNotifications.addListener('pushNotificationActionPerformed',async (notification) => {
        if (!user) {
          const apiUrl = notification.notification.data.url;
          console.log("apiUrl "+apiUrl);
          if (apiUrl) {
            try {
              const response = await fetch(apiUrl);       
              const data = await response.json();
              if (data.token) {
                localStorage.setItem('jwtToken', data.token); 
                setUser(data.user);
                console.log('User logged in: '+data.user.eMail);
              }
            } catch (error) {
              console.error('Error handling push notification action:', error);
            }
          }     
        }
        else {
          console.log('Push action performed: ' + JSON.stringify(notification) + ' Nothing to do!');
        }
      });
    }
  }, [runningNative]);
  
  // Device Info
  useEffect(() => {
    if (runningWeb && deviceInfo) {
      const isIPhoneWebKit = deviceInfo ? (deviceInfo.device === 'iPhone' && deviceInfo.engine === 'WebKit' && deviceInfo.browser?.startsWith("Safari")) : false;
      const isIPadWebKit = deviceInfo ? (deviceInfo.device === 'iPad' && deviceInfo.engine === 'WebKit' && deviceInfo.browser?.startsWith("Safari")) : false;
      const isAndroidChrome = deviceInfo ? (deviceInfo.os?.includes("Android") && deviceInfo.browser?.includes("Chrome")) : false;
      const isAndroidSamsung = deviceInfo ? (deviceInfo.os?.includes("Android") && deviceInfo.browser?.includes("Samsung")) : false;
      const isOlderIOS = (() => {
        if (deviceInfo.os?.startsWith("iOS")) {
          const iOSVersion = deviceInfo.os.split(" ")[1];
          return isNaN(parseFloat(iOSVersion)) ? true : parseFloat(iOSVersion) < 16.4;
        } 
        else if (deviceInfo.browser?.startsWith("Safari") && deviceInfo.type === "tablet") {
          const browserParts = deviceInfo.browser.split(" ");
          const safariVersion = parseFloat(browserParts[1]);
          return isNaN(safariVersion) ? true : safariVersion < 16.4;
        }        
        return false;
      })();

      // iPhone
      if (isIPhoneWebKit) {
        if (isOlderIOS) {
          setShowIPhoneIpadUnsupportedOverlay(true);
        }
        else {
          setShowIPhoneOverlay(deviceInfo.isStandalone === undefined || !deviceInfo.isStandalone);
        }
      }
      else if (isIPadWebKit) {
        if (isOlderIOS) {
          setShowIPhoneIpadUnsupportedOverlay(true);
        }
        else {
          setShowIPadOverlay(deviceInfo.isStandalone === undefined || !deviceInfo.isStandalone);
        }
      }
      else if (isAndroidChrome) {
        console.log("Checking Install Prompt");
        try {
          window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            // Save the event because you'll need to trigger it later.
            deferredPromptRef.current = e;
            setShowInstallPrompt(true);
          });
        }
        catch (e){
          console.log("Error preparing install prompt", e);  
        }
        finally {
          setShowAndroidOverlay(deviceInfo.isStandalone === undefined || !deviceInfo.isStandalone);
        }    
      } 
      else if (isAndroidSamsung) {
        console.log("Checking Install Prompt");
        try {
          window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            // Save the event because you'll need to trigger it later.
            deferredPromptRef.current = e;
            setShowInstallPrompt(true);
          });
        }
        catch (e){
          console.log("Error preparing install prompt", e);  
        }
        finally {
          setShowAndroidOverlay(deviceInfo.isStandalone === undefined || !deviceInfo.isStandalone);
        }    
      }
      indexlog("DeviceInfo: Device: "+deviceInfo.device+", Vendor: "+deviceInfo.vendor+", Type: "+deviceInfo.type+", OS: "+deviceInfo.os+", Browser: "+deviceInfo.browser+", Engine: "+deviceInfo.engine+", Standalone: "+deviceInfo.isStandalone);
      console.log("DeviceInfo: Device: "+deviceInfo.device+", Vendor: "+deviceInfo.vendor+", Type: "+deviceInfo.type+", OS: "+deviceInfo.os+", Browser: "+deviceInfo.browser+", Engine: "+deviceInfo.engine+", Standalone: "+deviceInfo.isStandalone);
    }
  }, [deviceInfo, indexlog, runningWeb]);

  // Save promo code locally
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const promoCode = urlParams.get('promo');
    if (promoCode) {
      localStorage.setItem('promoCode', promoCode);
      document.cookie = 'promoCode=' + promoCode + '; path=/; max-age=86400';
    }
  }, []);

  // Show About Modal 
  useEffect(() => {
    if (runningNative) {
      const storedValue = localStorage.getItem('showAboutModal');
      if (storedValue === 'false') {
        setshowAboutModal(false);
      }
    }
    else {
      const isStandalone = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
      const storedValue = localStorage.getItem('showAboutModal');
      
      // Determine modal visibility based on the conditions described:
      // 1. If 'showAboutModal' in localStorage is set to 'false', don't show the modal.
      // 2. If 'showAboutModal' in localStorage doesn't exist or is set to 'true' and 'isStandalone' is true, don't show the modal.
      // 3. If 'showAboutModal' in localStorage doesn't exist or is set to 'true' and 'isStandalone' is false or undefined, show the modal.
      if (storedValue === 'false' || (isStandalone && (storedValue === null || storedValue === 'true'))) {
        setshowAboutModal(false);
      } else {
        setshowAboutModal(true);
      }
    } 
  }, [runningNative]);

  const clearBadge = useCallback(() => {
    if (runningWeb) {
      if (!navigator.onLine) {
        console.log('Device is offline, badge will not be cleared.');
        return; // Exit the function if offline
      }
      if ('clearAppBadge' in navigator) {
        navigator.clearAppBadge().catch(error => {
          console.error('Failed to clear badge:', error);
        });
      }
    }   
  }, [runningWeb]);

  const handleVisibilityChange = useCallback(() => {
    if (runningWeb && document.visibilityState === 'visible') {
      console.log("Clearing badge and checking push permissions...");
      if (deviceInfo.isStandalone) {
        checkPushPermission();
        clearBadge();
      }
    }
  }, [deviceInfo, checkPushPermission, clearBadge, runningWeb]);

  const handleNetworkChange = useCallback(() => {
    if (runningWeb) {
      const online = navigator.onLine;
      setShowOfflineOverlay(!online);
      if (online) {
        console.log('Device is back online, attempting to clear badge...');
        clearBadge();
      } else {
        console.log('Device is offline.');
      }
    }
  }, [clearBadge, runningWeb]);

  useEffect(() => {
    window.addEventListener('online', handleNetworkChange);
    window.addEventListener('offline', handleNetworkChange);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('online', handleNetworkChange);
      window.removeEventListener('offline', handleNetworkChange);
    };
  }, [handleNetworkChange, handleVisibilityChange]);

  const handleCloseOverlay = () => {
    setShowIPhoneOverlay(false);
    setShowIPadOverlay(false);
    setShowAndroidOverlay(false);
    setShowOfflineOverlay(false);
    setShowIPhoneIpadUnsupportedOverlay(false);
  };

  const handleToggleChange = (isChecked) => {
    if (isChecked) {
      localStorage.setItem('showAboutModal', 'false');
      setshowAboutModal(false); 
    }
  }

  return (
    <IonApp>
      <GlobalLoading />
      <LoadingEventListener />
      <IonReactRouter>
        <IonPage>
          {showIPhoneOverlay && <IPhoneInstallInstructionsOverlay onClose={handleCloseOverlay} />}
          {showIPadOverlay && <IPadInstallInstructionsOverlay onClose={handleCloseOverlay} />}
          {showAndroidOverlay && <AndroidInstallInstructionsOverlay onClose={handleCloseOverlay} />}
          {showIPhoneIpadUnsupportedOverlay && <IPhoneiPadUnsupportedOverlay onClose={handleCloseOverlay} />}
          {showOfflineOverlay && <OfflineOverlay onClose={handleCloseOverlay} />}

          {/* Modal Component */}
          <IonModal isOpen={showAboutModal} onDidDismiss={() => setshowAboutModal(false)}>
            <IonHeader>
              <IonToolbar>
                <IonTitle>{t('aboutPingAliveModal.aboutPingAlive')}</IonTitle>
                <IonButtons slot="end">
                  <IonButton
                    onClick={async () => {
                      setshowAboutModal(false);
                      if (showInstallPrompt && deferredPromptRef.current) {
                        // Trigger the install prompt
                        deferredPromptRef.current.prompt();
                        await deferredPromptRef.current.userChoice;
                        deferredPromptRef.current = null; // Reset deferredPrompt after use
                      }
                    }}
                  >
                    {t('aboutPingAliveModal.letsGo')}
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
              <IonList lines="none">
                <IonItem><strong>{t('aboutPingAliveModal.whatIsPingAlive')}</strong></IonItem> 
                <IonList inset={true} lines="inset">
                  <IonItem>
                    <IonIcon slot="start" icon={paw}></IonIcon>
                    {t('aboutPingAliveModal.reason1')}
                  </IonItem>
                  <IonItem>
                    <IonIcon slot="start" icon={planet}></IonIcon>
                    {t('aboutPingAliveModal.reason2')}
                  </IonItem>
                  <IonItem>
                    <IonIcon slot="start" icon={fitness}></IonIcon>
                    {t('aboutPingAliveModal.reason3')}
                  </IonItem>
                </IonList>
                <IonItem><IonNote>{t('aboutPingAliveModal.note1')}</IonNote></IonItem>
              </IonList>
              <IonList lines="none">
                <IonItem button onClick={() => setshowHowItWorksModal(true)}><IonLabel><strong>{t('aboutPingAliveModal.howDoesItWork')}</strong></IonLabel></IonItem>
                <IonList inset={true} lines="inset">
                  <IonItem>
                    <IonIcon slot="start" icon={checkmark}></IonIcon>
                    {t('aboutPingAliveModal.explain1')}
                  </IonItem>
                  <IonItem>
                    <IonIcon slot="start" icon={contract}></IonIcon>
                    {t('aboutPingAliveModal.explain2')}
                  </IonItem>
                  <IonItem>
                    <IonIcon slot="start" icon={partlySunny}></IonIcon>
                    {t('aboutPingAliveModal.explain3')}
                  </IonItem>
                </IonList>
              </IonList>
              <IonList lines="none">
                <IonItem><IonLabel><strong>{t('aboutPingAliveModal.whatElseDoYouNeed')}</strong></IonLabel></IonItem>
                <IonList inset={true} lines="inset">
                  <IonItem>
                    <IonIcon slot="start" icon={arrowForward}></IonIcon>
                    {t('aboutPingAliveModal.require1')}
                  </IonItem>
                  <IonItem>
                    <IonIcon slot="start" icon={arrowForward}></IonIcon>
                    {t('aboutPingAliveModal.require2')}
                  </IonItem>
                  <IonItem>
                    <IonIcon slot="start" icon={alertOutline}></IonIcon>
                    {t('aboutPingAliveModal.require3')}
                  </IonItem>
                </IonList>
                <IonItem><IonNote>{t('aboutPingAliveModal.note3')}</IonNote></IonItem>
                <IonButton expand="block" onClick={() => setshowAboutModal(false)}>{t('aboutPingAliveModal.letsGo')}</IonButton>
                <IonItem>
                  <IonToggle
                    checked={!showAboutModal}
                    onIonChange={e => handleToggleChange(e.detail.checked)}
                  >
                    {t('aboutPingAliveModal.hidePermanent')}
                  </IonToggle>
                </IonItem>
              </IonList>
            </IonContent>
          </IonModal>
          <IonModal isOpen={showHowItWorksModal}>
            <IonHeader>
              <IonToolbar>
                <IonTitle>{t('aboutPingAliveModal.howDoesItWork')}</IonTitle>
                <IonButtons slot="end">
                  <IonButton onClick={() => setshowHowItWorksModal(false)}>{t('close')}</IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
              <IonItem><p>{t('aboutPingAliveModal.howDoesItWorkDetail1')}</p></IonItem>
              <IonItem><p>{t('aboutPingAliveModal.howDoesItWorkDetail2')}</p></IonItem>
              <IonItem><p>{t('aboutPingAliveModal.howDoesItWorkDetail3')}</p></IonItem>
              <IonItem><p>{t('aboutPingAliveModal.howDoesItWorkDetail4')}</p></IonItem>
              <IonItem><p>{t('aboutPingAliveModal.howDoesItWorkDetail5')}</p></IonItem>
              <IonItem><p>{t('aboutPingAliveModal.howDoesItWorkDetail6')}</p></IonItem>
            </IonContent>
          </IonModal>
          <IonContent>
            <IonRouterOutlet>
              <Route path="/" exact component={MainPage} />
              <Route path="/login" component={LoginForm} />
              <Route path="/register" component={RegistrationForm} />
              {/* Additional routes as needed */}
            </IonRouterOutlet>
          </IonContent>
        </IonPage>
      </IonReactRouter>
    </IonApp>
  );
}

const GlobalLoading = () => {
  const { isLoading, hideLoading, message } = useLoading();

  return (
      <IonModal isOpen={isLoading} onDidDismiss={hideLoading} backdropDismiss={false}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
              <IonSpinner name="crescent" />
              <IonText>{message}</IonText>
          </div>
      </IonModal>
  );
};

export default App;
