export const handleDeleteAccount = async (user, getCookie, redirect, logout, t) => {
    if (window.confirm(t('deleteAccountConfirmQuestion'))) {
        try {
            const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
            const token = getCookie('jwtToken');
            const response = await fetch(`${API_BASE_URL}/delete-account/${user.id}`, {
                method: 'DELETE',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                },
            });

            if (response.ok) {
                await logout(); // Perform a proper logout
                alert(t('deleteAccountSuccessMessage'));
                redirect.push('/');
            } else {
                const errorData = await response.json();
                alert(`Error: ${errorData.error}`);
            }
        } 
        catch (error) {
            console.error('Error deleting account:', error);
            alert(t('deleteAccountErrorMessage'));
        }
    }
};

  