import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { useUser } from './helpers/UserContext.js';
import { useHistory } from 'react-router-dom';
import { IonPage, IonHeader, IonToolbar, IonSelect, IonSelectOption, IonSearchbar, IonToast, IonNote, IonDatetime, IonTitle, IonRow, IonContent, IonButton, IonRange, IonIcon, IonButtons, IonListHeader, IonImg, IonLabel, IonItem, IonModal, IonList, IonInput, IonText, IonToggle, IonFooter } from '@ionic/react';
import { enter, add, exit, cog, arrowBack, heart, refreshCircleOutline, send, arrowForward, informationCircleOutline, alertCircleOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

import UserList from './helpers/UserList.js';
import UserInput from './helpers/UserInput.js';
import SubscriptionList from './helpers/SubscriptionList';
import isTokenExpiringSoon from './helpers/checkTokenExpire.js';
import {updateUserSettings} from './helpers/updateUserSettings.js';
import Countdown from './helpers/Countdown';
import HelpPopover from './helpers/HelpPopOver';
import { timezones } from './helpers/timezones';
import ConsoleDisplay from './helpers/ConsoleDisplay';
import useLogger from './helpers/useLogger';
import PromoDetails from './helpers/PromoDetails.js'

import handleAliveButtonClick from './actions/aliveButtonClick.js';
import handleDatePickerChange from './actions/datePickerChange.js';
import toggleSetting from './actions/toggleSetting.js';
import removeFromUserList from './actions/removeFromUserList.js';
import addToUserList from './actions/addToUserList.js';
import { removeFromSubscriptionList } from './actions/removeFromSubscriptionList';
import { handleDeleteAccount } from './actions/deleteAccount';
import changePassword from './actions/changePassword';
import { subscribeToPush } from './actions/subscribeToPush';

import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';

const MainPage = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const mainPageVersion = 'v0.0.0.575';
  const { indexlog, indexerror } = useLogger('MainPage');
  const { user, setUser, error, logout, getCookie, parseJwt, pushPermission, setPushPermission, isPushPermissionModalOpen, setIsPushPermissionModalOpen } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [fetched, setFetched] = useState(false);
  const [newMonitorId, setNewMonitorId] = useState('');
  const [newAlertId, setNewAlertId] = useState('');
  const [newAlertLabel, setNewAlertLabel] = useState('');
  const [newMonitorLabel, setNewMonitorLabel] = useState('');
  const [showSettings, setShowSettings] = useState(false);
  const [showLegalModal, setShowLegalModal] = useState(false);
  const [showAlertUsersEmptyToast, setShowAlertUsersEmptyToast] = useState(false);
  const [showFirstSteps, setShowFirstSteps] = useState(() => {
    // Read the initial value from localStorage or default to true
    const storedValue = localStorage.getItem('showFirstSteps');
    return storedValue !== null ? storedValue === 'true' : true;
  });
  const intervalRef = useRef(null);
  const prevUserRef = useRef(user);
  const [subscriptions, setSubscriptions] = useState([]);
  const redirect = useHistory();
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [rangeValueInterval, setRangeValueInterval] = useState(1);
  const [rangeValueGrace, setRangeValueGrace] = useState(1);
  const [selectedLanguage, setSelectedLanguage] = useState('en-US');
  const [selectedTimezone, setSelectedTimezone] = useState('Europe/Berlin');
  const [showTimezoneModal, setShowTimezoneModal] = useState(false);
  const [timezoneSearchText, setTimezoneSearchText] = useState('');
  const [filteredTimezones, setFilteredTimezones] = useState(timezones);
  const [showConsoleModal, setShowConsoleModal] = useState(false);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [pressTimer, setPressTimer] = useState(null);
  const [checkPermissionFeedback, setCheckPermissionFeedback] = useState('');
  const [promoDetails, setPromoDetails] = useState(null);
  const [registrationDate, setRegistrationDate] = useState('');
  const [showCountdownReset,setShowCountdownReset] = useState(false);
  const runningNative = Capacitor.isNativePlatform();
  const runningWeb = !Capacitor.isNativePlatform();

  // Testing
  
  const { t, i18n } = useTranslation();

  // Update some value based one the user object once it is there
  useEffect(() => {
    if (user && user.notificationInterval !== undefined) {
      setRangeValueInterval(user.notificationInterval / (60 * 60 * 1000));
    }
    if (user && user.gracePeriod !== undefined) {
      setRangeValueGrace(user.gracePeriod / (60 * 60 * 1000));
    }
    if (user && user.language !== undefined) {
      setSelectedLanguage(user.language);
    }
    if (user && user.timezone !== undefined) {
      setSelectedTimezone(user.timezone);
    }
    if (user && user.subscriptionDetails !== undefined) {
      setSubscriptions(user.subscriptionDetails);
    }
    if (user && user.registationDate !== undefined) {
      setRegistrationDate(user.registationDate);
    }
  }, [user]);

  // Get Promo details if the user has a promo code associated
  useEffect(() => {
    const fetchPromoDetails = async () => {
      const token = getCookie('jwtToken');
      if (!token || !user || !user.promoCode) {
          return;
      }
      try {
        const response = await fetch(`${API_BASE_URL}/promo-details/${user.promoCode}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          indexerror(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        //console.log("data:" ,data);
        setPromoDetails(data);
      } 
      catch (error) {
        indexerror('Failed to fetch promo details:', error);
      }
    };

    fetchPromoDetails();
  }, [user, getCookie, indexerror, API_BASE_URL]);

  // Show warning toast if user turns on pingMe but has not users to alert
  useEffect(() => {
    const handleToastDisplay = () => {
      const showAlertUsersEmpty = localStorage.getItem('ShowAlertUsersEmpty');
      const timestamp = showAlertUsersEmpty ? new Date(parseInt(showAlertUsersEmpty, 10)) : null;
      const timeNow = new Date();

      // Check conditions to show the toast
      if (user?.pingMe && 
          (!user.userIdsToAlert || user.userIdsToAlert.length === 0) && 
          showAlertUsersEmpty &&
          (timeNow - timestamp) > 10800000) { // 3 hours in milliseconds
          //(timeNow - timestamp) > 3000) { // 3 seconds
        setShowAlertUsersEmptyToast(true);
      } else {
        setShowAlertUsersEmptyToast(false);
      }
    };

    handleToastDisplay();
    const intervalId = setInterval(handleToastDisplay, 600000); // 600000 milliseconds = 10 minutes
    //const intervalId = setInterval(handleToastDisplay, 30000); // 600000 milliseconds = 10 minutes

    // Cleanup the interval when the component unmounts or the user object changes
    return () => clearInterval(intervalId);

  }, [user]);

  // Fetch user details and handle token refresh
  useEffect(() => {
    // Clear the badge when the app is opened
    const fetchUserDetails = async () => {
      const token = getCookie('jwtToken');
      if (token && isTokenExpiringSoon(token, parseJwt)) {
        try {
          const refreshResponse = await fetch(`${API_BASE_URL}/token`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });

          if (refreshResponse.ok) {
            const { token: newToken } = await refreshResponse.json();
            document.cookie = `jwtToken=${newToken}; path=/; Secure; SameSite=Strict`;
          } else {
            indexerror('Failed to refresh token');
          }
        } catch (error) {
          indexerror('Error refreshing token:', error);
        }
      }

      if (token) {
        try {
          const decodedToken = parseJwt(token);
          const userId = decodedToken.userId;

          const response = await fetch(`${API_BASE_URL}/user-details/${userId}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });

          if (response.ok) {
            const userDetails = await response.json();
            prevUserRef.current = userDetails;
            setUser(userDetails);
            setFetched(true);

            // Check if last visit was more than 15 minutes ago
            const lastVisitDate = new Date(userDetails.lastVisit);
            const now = new Date();
            const fifteenMinutes = 15 * 60 * 1000;
            if (now - lastVisitDate > fifteenMinutes) {
              if (!userDetails.dailyReminder) {
                setShowCountdownReset(true);
              }
            }
          } else {
            indexerror('Failed to fetch user details');
          }
        } catch (error) {
          if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
            // Specific handling for network errors (offline scenario)
            indexlog('Unable to connect to the server. Please check your internet connection.');
          } else {
            indexerror('Error fetching user details:', error);
          }
        } finally {
          setIsLoading(false);
          setFetched(true);
        }
      } else {
        setIsLoading(false);
      }
    };

    if (!fetched) {
      fetchUserDetails();
    }
  }, [fetched, setUser, parseJwt, getCookie, indexlog, indexerror, API_BASE_URL]);

  // Fetch user data every minute
  const fetchUserData = useCallback(async () => {
    const token = getCookie('jwtToken');
    if (!token) {
      indexerror('No JWT token found');
      await logout();
      window.location.href = '/';
      return;
    }
    if (!user || !user.id) {
      indexerror('User object is not available');
      return;
    }
    try {
      const response = await fetch(`${API_BASE_URL}/user-details/${user.id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        indexerror('Failed to fetch user data');
        return;
      }
      const userData = await response.json();
      indexlog('Attempting user state update (on change or every 60s...)');

      // Compare with the previous state before updating otherwise this will end up in a loop
      if (JSON.stringify(prevUserRef.current) !== JSON.stringify(userData)) {
        indexlog('...User state updated');
        prevUserRef.current = userData; // Update the ref with the new data
        setUser(userData);
      }
      else {
        indexlog('...Nothing changed');
      }
    } catch (error) {
      if (error.name === 'TypeError' && error.message.includes('Failed to fetch')) {
        // Handle network errors specifically
        indexlog('Network error: Unable to fetch user details. Please check your internet connection.');
      } else {
        indexerror('Error fetching user details:', error);
      }
    }
  }, [user, getCookie, setUser, logout, indexlog, indexerror, API_BASE_URL]);

  useEffect(() => {
     if (user && user.id) {
      // Fetch initial data
      fetchUserData();

      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      intervalRef.current = setInterval(() => {
        fetchUserData();
      }, 60000); // Update every minute

      return () => {
        clearInterval(intervalRef.current);
      };
    }
  }, [user, fetchUserData]);

  // Remove push subscriptions
  const removeSubscription = async (index) => {
    try {
      const updatedSubscriptions = await removeFromSubscriptionList(index, subscriptions, user, setUser, getCookie, parseJwt);
      setSubscriptions(updatedSubscriptions);
    } catch (error) {
      indexerror('Failed to remove subscription', error);
    }
  };

  // Countdown if user in interval mode
  const memoizedCountdown = useMemo(() => {
    return user && user.pingMe ? <Countdown user={user} /> : null;
  }, [user]);

  // Show Settings if user clicks it
  const handleToggleSettings = () => {
    setShowSettings(!showSettings);
    //checkPushPermission();
  };

  // Toggle Switch handler
  const handleToggleChange = async (settingName) => {
    if (settingName === 'pushNotificationSubscription') {
      if ( pushPermission === 'granted' ) {
        setCheckPermissionFeedback(null);
        const currentSettingValue = user.pushNotificationSubscription;
        const newSettingValue = !currentSettingValue; // This captures the intended new state

        if (newSettingValue) {
          // If the toggle is switched on, attempt to subscribe to push notifications
          try {
            const updatedSubscriptions = await subscribeToPush(user);
            if (updatedSubscriptions) {
                await updateUserSettings('subscriptionDetails', updatedSubscriptions, getCookie, parseJwt);
                await updateUserSettings('pushNotificationSubscription', true, getCookie, parseJwt);
                setUser(prevUser => ({
                ...prevUser,
                subscriptionDetails: updatedSubscriptions,
                pushNotificationSubscription: true
                }));
                setSubscriptions(updatedSubscriptions);
            }
          } 
          catch (error) {
              indexerror('Error subscribing to push notifications:', error);
          }
        } 
        else {
          // If the toggle is switched off, clear subscriptions and set pingMe to false
          try {
            await removeFromSubscriptionList('all', user.subscriptionDetails, user, setUser, getCookie, parseJwt);
            setUser(prevUser => ({
                ...prevUser,
                pushNotificationSubscription: false,
                pingMe: false,
                subscriptionDetails: []
            }));
            setSubscriptions([]);
            await updateUserSettings('pushNotificationSubscription', newSettingValue, getCookie, parseJwt);
            await updateUserSettings('pingMe', false, getCookie, parseJwt);
          } catch (error) {
            indexerror('Failed to update settings when unchecking pushNotificationSubscription', error);
            // Revert the toggle on error
            setUser(prevUser => ({
                ...prevUser,
                pushNotificationSubscription: true
            }));
          }
        }
      } 
      else {
        // Revert the toggle change and display push denied message
        setCheckPermissionFeedback(t('permissionDeniedFeedback'));
        setIsPushPermissionModalOpen(true);
        const currentSettingValue = user.pushNotificationSubscription;
        setUser(prevUser => ({
            ...prevUser,
            pushNotificationSubscription: currentSettingValue
        }));
      }     
    }
    else if (settingName === 'pingMe') {
      toggleSetting(settingName, user, setUser, getCookie, parseJwt);
      const newValue = !user.pingMe;
      if (newValue) {
        localStorage.setItem('ShowAlertUsersEmpty', Date.now().toString());
      }
      else {
        localStorage.removeItem('ShowAlertUsersEmpty');
      }
    } 
    else {
      // For other settings, use the existing toggle logic
      toggleSetting(settingName, user, setUser, getCookie, parseJwt);
    }
  };

  // Radio Button Handler
  const handleReminderChange = async (value) => {
    indexlog("Selected Value: ", value);
    const dailyReminderValue = value === "daily";

    await updateUserSettings('dailyReminder', dailyReminderValue, getCookie, parseJwt);
    
    setUser(prevUser => ({
      ...prevUser,
      dailyReminder: dailyReminderValue
    }));
  };

  // Time Picker Handler
  const handleTimeChange = async (event) => {
    const selectedTime = event.detail.value; // ISO datetime string
    const date = new Date(selectedTime);
    const hour = date.getHours();
    const minute = date.getMinutes();
  
    const slot = Math.floor(minute / 15) + 1;
  
    try {
      await updateUserSettings('dailyReminderHour', hour, getCookie, parseJwt);
      await updateUserSettings('dailyReminderSlot', slot, getCookie, parseJwt);

      setUser(prevUser => ({
        ...prevUser,
        dailyReminderHour: hour,
        dailyReminderSlot: slot
      }));
    } catch (error) {
      indexerror("Failed to update settings:", error);
    }
  };
  
  // Function to calculate the initial time string for IonDatetime
  function getInitialTime(hour, slot, timezone) {
    const minutes = slot === 1 ? 0 : slot === 2 ? 15 : slot === 3 ? 30 : 45;

    const date = new Date();
    
    // Convert date to the user's timezone
    date.setUTCHours(hour, minutes, 0, 0);  // Set UTC hours based on local time calculation
    const dateString = date.toLocaleString('en-US', { timeZone: timezone, hour12: false });
    const dateInTimeZone = new Date(dateString);
  
    return dateInTimeZone.toISOString();
  }  

  // Ask for confirmation before removing an entry
  const confirmAndRemove = (listType, userId) => {
    if (window.confirm(t('removeUserConfirmQuestion'))) {
      removeFromUserList(listType, userId, user, setUser, getCookie, parseJwt);
    }
  };

  // Language picker
  const handleLanguageChange = async (newLanguage) => {
    indexlog("Selected Language: ", newLanguage);
    setSelectedLanguage(newLanguage);

    try {
        await updateUserSettings('language', newLanguage, getCookie, parseJwt);
        await i18n.changeLanguage(newLanguage);
        indexlog('Language updated successfully');
        setUser(prevUser => ({
            ...prevUser,
            language: newLanguage
        }));
    } catch (error) {
        indexerror('Failed to update language:', error);
    }
  };

  // Timezone picker
  const handleTimezoneInput = (event) => {
    const query = event.detail.value ? event.detail.value.toLowerCase() : '';
    setTimezoneSearchText(query);
    setFilteredTimezones(timezones.filter(tz => tz.label.toLowerCase().includes(query)));
  };

  const resetAndCloseModal = () => {
    setShowTimezoneModal(false);
    setFilteredTimezones(timezones); 
    setTimezoneSearchText(''); 
  };

  const handleTimezoneChange = async (newTimezone) => {
    indexlog("Selected timezone: ", newTimezone);
    setSelectedTimezone(newTimezone);

    try {
        await updateUserSettings('timezone', newTimezone, getCookie, parseJwt);
        indexlog('Timezone updated successfully');

        setUser(prevUser => ({
          ...prevUser,
          timezone: newTimezone
      }));
    } catch (error) {
        indexerror('Failed to update timezone:', error);
    }
  };

  // Check passwords on change
  const handleChangePassword = () => {
    changePassword(
      user, 
      newPassword, 
      confirmPassword, 
      getCookie, 
      setShowChangePasswordModal, 
      setPasswordError, 
      setNewPassword, 
      setConfirmPassword,
      t
    );
  };

  // Request Push Notification Status Update
  const handleCheckPermission = async () => {
    if ("Notification" in window || Capacitor.isNativePlatform()) {
      let permission;
      if (Capacitor.isNativePlatform()) {
        let permissionStatus = await PushNotifications.checkPermissions();
        if (permissionStatus.receive === 'prompt') {
          permissionStatus = await PushNotifications.requestPermissions();
        }
        permission = permissionStatus.receive;
      }
      else {
        permission = await Notification.requestPermission();
      }
      indexlog('Requested Push Notification Permission:', permission);
      setPushPermission(permission);
      setIsPushPermissionModalOpen(permission !== 'granted');

      if (permission !== 'granted') {
        setCheckPermissionFeedback(t('permissionDeniedFeedback'));
      } else {
        setCheckPermissionFeedback(null);
        try {
          const updatedSubscriptions = await subscribeToPush(user);
          if (updatedSubscriptions) {
              await updateUserSettings('subscriptionDetails', updatedSubscriptions, getCookie, parseJwt);
              await updateUserSettings('pushNotificationSubscription', true, getCookie, parseJwt);
              setUser(prevUser => ({
              ...prevUser,
              subscriptionDetails: updatedSubscriptions,
              pushNotificationSubscription: true
              }));
              setSubscriptions(updatedSubscriptions);
          }
        } catch (error) {
          indexerror('Error subscribing to push notifications:', error);
        }
      }
    }
    else {
      setIsPushPermissionModalOpen(true);
    }    
  };

  // Check for token and device_id and redirect to login if token is missing but device_id exists
  useEffect(() => {
    try {
      const token = getCookie('jwtToken');
      const device_id = localStorage.getItem('device_id');

      const navigatedFromBackButton = redirect.location.state?.fromLoginPageBackButton;

      if (!token && device_id && !navigatedFromBackButton) {
        indexlog('No valid token found but deviceId exists. Redirecting to login.');
        redirect.push('/login');
      }
    } catch (error){
      indexerror('Error during redirection:', error);
    }
    
  }, [getCookie, redirect, indexlog, indexerror]);

  // Clear the feedback message when the modal opens
  useEffect(() => {
    if (isPushPermissionModalOpen) {
      setCheckPermissionFeedback('');  
    }
  }, [isPushPermissionModalOpen]);

  const closePushPermissionModal = () => {
    setIsPushPermissionModalOpen(false);
  }

  // ConsoleDisplay Handlers
  const handleMouseDown = () => {
    // Start the timer when the user presses down
    const timer = setTimeout(() => {
      setShowConsoleModal(true); // Show the modal if held for 5 seconds
    }, 5000); // 5000ms = 5 seconds
    setPressTimer(timer);
  };

  const handleMouseUp = () => {
    // Clear the timer if the user releases before 5 seconds
    clearTimeout(pressTimer);
  };

  // Handle showFirstSteps changes
  useEffect(() => {
    localStorage.setItem('showFirstSteps', showFirstSteps);
  }, [showFirstSteps]);

  const handleShowFirstStepsChange = (event) => {
    setShowFirstSteps(event.detail.checked);
  };

  if (isLoading || !i18n.isInitialized) {
    return <div>{t('loading')}</div>;
  }

  return (
    <IonPage>
      <IonHeader id="header">
        <IonToolbar >
          <IonTitle class='ion-text-center'>
            <IonImg 
              src="/ping-alive-logo/logo192.png" 
              alt="logo-image" 
              style={{ height: '64px'}}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onTouchStart={handleMouseDown}
              onTouchEnd={handleMouseUp}
            />
            
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonFooter >
        <IonToolbar style={{ paddingBottom: 0 }}>
          {!user && (
            <IonButtons slot="end">
              <IonButton fill="outline" routerLink="/register">
                {t('register')}
                <IonIcon slot="end" icon={add}></IonIcon>
              </IonButton>
              <IonButton fill="outline" routerLink="/login">
                {t('login')}
                <IonIcon slot="end" icon={enter}></IonIcon>
              </IonButton>
            </IonButtons>
          )}
          {user && showSettings && (
            <IonButtons slot="end">
              <IonButton fill="outline" onClick={logout}>
                {t('logout')}
                <IonIcon slot="end" icon={exit}></IonIcon>
              </IonButton>
            </IonButtons>
          )}
          {user && showSettings && (
            <IonButtons slot="start">
              <IonButton fill="outline" onClick={handleToggleSettings}>
                {t('back')}
                <IonIcon slot="start" icon={arrowBack}></IonIcon>
              </IonButton>
            </IonButtons>
          )}
          {user && !showSettings && (
            <IonButtons slot="end">
              <IonButton fill="outline" onClick={logout}>
                {t('logout')}
                <IonIcon slot="end" icon={exit}></IonIcon>
              </IonButton>
            </IonButtons>
          )}
          {user && !showSettings && (
            <IonButtons slot="start">
              <IonButton fill="outline" onClick={handleToggleSettings}>
                {t('settings')}
                <IonIcon slot="start" icon={cog}></IonIcon>
              </IonButton>
            </IonButtons>
          )}     
        </IonToolbar>
      </IonFooter>
      <IonModal isOpen={showConsoleModal} onDidDismiss={() => setShowConsoleModal(false)} fullscreen={true}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Console Log</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setShowConsoleModal(false)}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <ConsoleDisplay />
        </IonContent>
      </IonModal>
      {user && (
        <>
          <IonToast
            isOpen={isPushPermissionModalOpen} 
            message={t('notificationPermissionDeniedMessage') + ' ' + t('notificationPermissionSettingsMessage')} 
            duration={7000}
            position="top"
            layout="stacked"
            swipeGesture="vertical"
            onDidDismiss={closePushPermissionModal}
            buttons={[
              {
                text: t('checkPushPermission'),
                handler: () => {
                  handleCheckPermission();
                }
              }
            ]}>
          </IonToast>
          {checkPermissionFeedback && checkPermissionFeedback !== '' && (
          <IonToast
            isOpen={!!checkPermissionFeedback}
            message={checkPermissionFeedback}
            duration={2000}
            position="top"
            positionAnchor="header"
            layout="stacked"
            swipeGesture="vertical"
            onDidDismiss={() => setCheckPermissionFeedback('')}
            color="danger"
          />)}
          <IonToast
            isOpen={showAlertUsersEmptyToast}
            message={t('pingMeUsersToAlertEmptyWarning')}
            duration={10000}
            position="top"
            positionAnchor="header"
            layout="stacked"
            swipeGesture="vertical"
            onDidDismiss={() => setShowAlertUsersEmptyToast(false)}
            color="danger"
          />
        </>
      )}
      <IonContent fullscreen={true} className="ion-padding" color="light">
        {!user && (
          <IonTitle>{t('appName')}</IonTitle>
        )}
        {!showSettings && user && (
          (!user.pushNotificationSubscription || pushPermission !== 'granted' || !user.dailyReminder || (user.userStatus > 0)) && (
            <IonList>
              <IonListHeader>
                <h3>{t('currentStatus')}</h3>
              </IonListHeader>
              {user && !user.pushNotificationSubscription && (
                <>
                <IonItem>
                  <IonText>{t('userHasNoPushSubscriptions')}</IonText>
                </IonItem>
                </>
              )}
              {user && pushPermission !== 'granted' && (
                <>
                <IonItem>
                  <IonText>{t('userLimitedToMonitoring')}</IonText>
                  <IonButton fill="clear" onClick={handleCheckPermission}>
                    <IonIcon slot="icon-only" icon={refreshCircleOutline}></IonIcon>
                  </IonButton>
                </IonItem>
                </>
              )}
              {user && user.userStatus > 0 && (
                <>
                <IonItem>
                  <IonButton color="danger" expand="block" size="large" onClick={() => handleAliveButtonClick(setUser, getCookie, parseJwt)}>{t('resetWarningsAndAlerts')}
                    <IonIcon slot="start" icon={heart}></IonIcon>
                    <IonIcon slot="end" icon={heart}></IonIcon>
                  </IonButton>
                </IonItem>
                </>
              )}
              {!user.dailyReminder && memoizedCountdown}
              {user && showCountdownReset && (
                <>
                <IonItem>
                  <IonButton color="warning" expand="block" size="large" onClick={() => { handleAliveButtonClick(setUser, getCookie, parseJwt); setShowCountdownReset(false);}}>{t('resetCountdown')}
                  </IonButton>
                </IonItem>
                </>
              )}
            </IonList>
          )
        )}
        {user && showFirstSteps &&
          <>
            <IonList lines="none">
              <IonItem>
                <IonToggle
                  checked={showFirstSteps}
                  onIonChange={handleShowFirstStepsChange}
                >
                  <strong>{t('firstSteps.headline1')}</strong>
                </IonToggle>
              </IonItem> 
              <IonList inset={true} lines="inset">
              {showFirstSteps && !showSettings && (
                <>
                  <IonItem>
                    <IonIcon slot="start" icon={informationCircleOutline}></IonIcon>
                    {t('firstSteps.step1a')}
                  </IonItem>
                  <IonItem>
                    <IonIcon slot="start" icon={alertCircleOutline}></IonIcon>
                      {t('firstSteps.step1b')}
                  </IonItem>
                  <IonItem button onClick={() => setShowLegalModal(true)}>
                    <IonIcon slot="start" icon={send}></IonIcon>
                    {t('firstSteps.step2')}
                  </IonItem>
                  <IonItem button onClick={() => setShowSettings(true)}>
                    <IonIcon slot="start" icon={cog}></IonIcon>
                    {t('firstSteps.step3')}
                  </IonItem>
                  <IonModal isOpen={showLegalModal} onDidDismiss={() => setShowLegalModal(false)} fullscreen={true}>
                    <IonHeader>
                      <IonToolbar>
                        <IonTitle>DSGVO, Cookies und Kosten</IonTitle>
                        <IonButtons slot="end">
                          <IonButton onClick={() => setShowLegalModal(false)}>{t('close')}</IonButton>
                        </IonButtons>
                      </IonToolbar>
                    </IonHeader>
                    <IonContent>
                      <IonItem>
                        <strong>{t('legalModal.DSGVO')}</strong>
                      </IonItem>
                      <IonItem>
                        <p>{t('legalModal.appDoesntKnowYou')}</p>
                      </IonItem>
                      <IonItem>
                        <p>{t('legalModal.appDoesntProcessData')}</p>
                      </IonItem>
                      <IonItem>
                        <p>{t('legalModal.appUsesEncryption')}</p>
                      </IonItem>
                      <IonItem>
                        <strong>{t('legalModal.cookies')}</strong>
                      </IonItem>
                      <IonItem>
                        <p>{t('legalModal.yesWeUseCookies')}</p>
                      </IonItem>
                      <IonItem>
                        <p>{t('legalModal.trackingForAds')}</p>
                      </IonItem>
                      <IonItem>
                        <strong>{t('legalModal.costs')}</strong>
                      </IonItem>
                      <IonItem>
                        <p>{t('legalModal.prepaidModel')}</p>
                      </IonItem>
                    </IonContent>
                  </IonModal>
                </>
              )}
              {user && showFirstSteps && showSettings && (
                <>
                  <IonItem>
                    <IonIcon slot="start" icon={arrowForward}></IonIcon>
                    {t('firstSteps.step4')}
                  </IonItem>
                  <IonItem>
                    <IonIcon slot="start" icon={arrowForward}></IonIcon>
                    {t('firstSteps.step5')}
                  </IonItem>
                  <IonItem>
                    <IonIcon slot="start" icon={arrowForward}></IonIcon>
                    {t('firstSteps.step6')}
                  </IonItem>
                  <IonItem>
                    <IonIcon slot="start" icon={arrowForward}></IonIcon>
                    {t('firstSteps.step7')}
                  </IonItem>
                </>
              )}
              </IonList>
              {user && showFirstSteps && !showSettings && (
                <IonItem><IonNote>{t('firstSteps.note1')}</IonNote></IonItem>
              )}
              {user && showFirstSteps && showSettings && (
                <IonItem><IonNote>{t('firstSteps.note2')}</IonNote></IonItem>
              )}
            </IonList>
          </>
        }
        {!showSettings && user && user.userIdsToAlert && user.userIdsToAlert.length > 0 && user.pingMe && pushPermission === 'granted' &&(
          <>
            <IonRow style={{ height: '15px' }}></IonRow>
            <IonList>
            <IonItem>
              <IonLabel><strong>{t('usersToAlert')}</strong></IonLabel>
            </IonItem>
            <UserList 
              users={user.userIdsToAlert}
              labels={user.userIdsToAlertLabels}
              onRemove={(userId) => confirmAndRemove('userIdsToAlert', userId)}
            /></IonList>
          </>
        )}

        {!showSettings && user && user.userIdsToMonitor && user.userIdsToMonitor.length > 0 && (
          <>
            <IonRow style={{ height: '15px' }}></IonRow>
            <IonList>
            <IonItem>
              <IonLabel>
                <strong>{t('usersToMonitor')}</strong>
              </IonLabel>
            </IonItem>
            <UserList
              users={user.userIdsToMonitor}
              labels={user.userIdsToMonitorLabels}
              onRemove={(userId) => confirmAndRemove('userIdsToMonitor', userId)}
              alert={true}
            /></IonList>
          </>
        )}

        {error && <IonText color="danger">{error}</IonText>}

        {showSettings && user && (
          <>
            <IonList>
              <IonItem>
                <HelpPopover keyword="helpReceivePushNotifications" />
                <IonToggle 
                  checked={user.pushNotificationSubscription}
                  onIonChange={() => handleToggleChange('pushNotificationSubscription')}
                >
                  {t('receivePushNotifications')}
                </IonToggle>
              </IonItem>

              {user.pushNotificationSubscription && (
                <>
                  <IonItem>
                    <HelpPopover keyword="helpPingMe" />
                    <IonToggle 
                      checked={user.pingMe}
                      onIonChange={() => handleToggleChange('pingMe')}
                    >
                      {t('pingMe')}
                    </IonToggle>
                  </IonItem>
                </>
              )}

              {user.pingMe && (
                <>
                  <IonItem>
                    <HelpPopover keyword="helpSecondPing" />
                    <IonToggle
                      checked={user.retryPushNotification}
                      onIonChange={() => handleToggleChange('retryPushNotification')}
                    >
                      {t('secondPing')}
                    </IonToggle>
                  </IonItem>
                  {showAdvancedSettings && (
                    <>
                      <IonItem>
                        <HelpPopover keyword="helpNotificationSettings" />
                        <IonSelect
                          cancelText={t('cancel')}
                          justify="space-between"
                          interface="action-sheet"
                          placeholder={user.dailyReminder ? t('daily') : t('interval')}
                          value={user.dailyReminder ? "daily" : "interval"}
                          onIonChange={e => handleReminderChange(e.detail.value)}
                        >
                          <div slot="label">
                            {t('notification_settings')}
                          </div>
                          <IonSelectOption value="daily">{t('daily')}</IonSelectOption>
                          <IonSelectOption value="interval">{t('interval')}</IonSelectOption>
                        </IonSelect>
                      </IonItem>
                    </>
                  )}
                  
                  {user.dailyReminder && (
                    <>
                      <IonDatetime 
                        presentation="time"
                        value={getInitialTime(user.dailyReminderHour, user.dailyReminderSlot, user.timezone)}
                        minuteValues="0,15,30,45"
                        preferWheel={true}
                        onIonChange={handleTimeChange}
                        showDefaultButtons={false}
                        size='cover'
                      ></IonDatetime>
                    </>
                  )}
                  {!user.dailyReminder && showAdvancedSettings && (
                    <>
                      <IonItem>
                        <IonRange 
                          labelPlacement="stacked" 
                          label={`${t('notificationInterval')} ${rangeValueInterval}`} 
                          min={1} 
                          max={36} 
                          step={1}
                          pin={true} 
                          value={rangeValueInterval}
                          onIonInput={({ detail }) => setRangeValueInterval(detail.value)}
                          onIonChange={({ detail }) => {
                            setRangeValueInterval(detail.value);
                            handleDatePickerChange('notificationInterval', detail.value, setUser, getCookie, parseJwt);
                          }}
                        >
                          <ion-label range-left>1 hour</ion-label>
                          <ion-label range-right>36 hours</ion-label>
                          
                        </IonRange>
                      </IonItem>
                      <IonItem>
                        <IonRange 
                          labelPlacement="stacked" 
                          label={`${t('gracePeriod')} ${rangeValueGrace}`}  
                          min={6} 
                          max={36} 
                          step={1}
                          pin={true} 
                          value={rangeValueGrace}
                          onIonInput={({ detail }) => setRangeValueGrace(detail.value)}
                          onIonChange={({ detail }) => {
                            setRangeValueGrace(detail.value);
                            handleDatePickerChange('gracePeriod', detail.value, setUser, getCookie, parseJwt);
                          }}
                        >
                          <ion-label range-left>1 {t('hours')}</ion-label>
                          <ion-label range-right>36 {t('hours')}</ion-label>
                        </IonRange>
                      </IonItem>
                    </>
                  )}
                </>
              )}
        </IonList>
              {user.pingMe && pushPermission === 'granted' && (
                <IonRow style={{ height: '15px' }}></IonRow>
              )}

              {user.pingMe && pushPermission === 'granted' && (
                <IonList>
                  <IonItem>
                    <IonLabel><strong>{t('usersToAlert')}</strong></IonLabel>
                    <HelpPopover keyword="helpUsersToAlert" />
                  </IonItem>
                  <UserList 
                    users={user.userIdsToAlert} 
                    labels={user.userIdsToAlertLabels} 
                    onRemove={(userId) => confirmAndRemove('userIdsToAlert', userId)} 
                    showRemoveButton={true}
                  />
                  <UserInput
                    arrayName="userIdsToAlert"
                    value={newAlertId}
                    label={newAlertLabel}
                    onValueChange={setNewAlertId}
                    onLabelChange={setNewAlertLabel}
                    onAdd={() => addToUserList(
                      'userIdsToAlert',
                      newAlertId,
                      newAlertLabel,
                      getCookie,
                      parseJwt,
                      setUser,
                      setNewAlertId,
                      setNewAlertLabel,
                      setNewMonitorId,
                      setNewMonitorLabel,
                      user
                    )}
                    setNewAlertId={setNewAlertId}
                    setNewAlertLabel={setNewAlertLabel}
                    setNewMonitorId={setNewMonitorId}
                    setNewMonitorLabel={setNewMonitorLabel}
                  />
                </IonList>
                
              )}
              {user.pingMe && pushPermission === 'granted'  && (
                <IonRow style={{ height: '15px' }}></IonRow>
              )}

              {!showAdvancedSettings && (
                <>
                  <IonItem>
                    <IonButton onClick={() => setShowAdvancedSettings(true)} fill="clear">
                      {t('showAdvancedSettings')}
                    </IonButton>
                  </IonItem>
                </>
              )}
              {showAdvancedSettings && (
                <>
                  <IonItem>
                    <IonButton onClick={() => setShowAdvancedSettings(false)} fill="clear">
                      {t('hideAdvancedSettings')}
                    </IonButton>
                  </IonItem>
                </>
              )}
              {showAdvancedSettings && (
                <>
                  <IonList>
                    <IonItem>
                      <IonLabel><strong>{t('usersToMonitor')}</strong></IonLabel>
                      <HelpPopover keyword="helpUsersToMonitor" />
                    </IonItem>
                    <UserList 
                      users={user.userIdsToMonitor} 
                      labels={user.userIdsToMonitorLabels} 
                      onRemove={(userId) => confirmAndRemove('userIdsToMonitor', userId)}
                      showRemoveButton={true} 
                    />
                    <UserInput
                      arrayName="userIdsToMonitor"
                      value={newMonitorId}
                      label={newMonitorLabel}
                      onValueChange={setNewMonitorId}
                      onLabelChange={setNewMonitorLabel}
                      onAdd={() => addToUserList(
                        'userIdsToMonitor',
                        newAlertId,
                        newAlertLabel,
                        getCookie,
                        parseJwt,
                        setUser,
                        setNewAlertId,
                        setNewAlertLabel,
                        setNewMonitorId,
                        setNewMonitorLabel,
                        user
                      )}
                      setNewAlertId={setNewAlertId}
                      setNewAlertLabel={setNewAlertLabel}
                      setNewMonitorId={setNewMonitorId}
                      setNewMonitorLabel={setNewMonitorLabel}
                    />
                  </IonList>

                <IonRow style={{ height: '15px' }}></IonRow>

                <SubscriptionList subscriptions={user.subscriptionDetails} onRemove={removeSubscription} />

                <IonRow style={{ height: '15px' }}></IonRow>
                
                <IonList>
                  <IonItem>
                    <IonSelect cancelText={t('cancel')}
                      justify="space-between" 
                      interface="action-sheet" 
                      placeholder={selectedLanguage} 
                      value={selectedLanguage} 
                      onIonChange={e => handleLanguageChange(e.detail.value)}
                    >
                      <div slot="label">
                        {t('language')}
                      </div>
                      <IonSelectOption value="de-DE">Deutsch</IonSelectOption>
                      <IonSelectOption value="en-US">English</IonSelectOption>
                      <IonSelectOption value="es-ES">Español</IonSelectOption>
                      <IonSelectOption value="fr-FR">Français</IonSelectOption>
                      <IonSelectOption value="it-IT">Italiano</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                </IonList>

                <IonItem onClick={() => setShowTimezoneModal(true)}>
                    <IonLabel>{t('timezone')}</IonLabel>
                    <IonLabel slot="end">{selectedTimezone}</IonLabel>
                </IonItem>

                <IonModal isOpen={showTimezoneModal} onDidDismiss={resetAndCloseModal}>
                    <IonHeader>
                        <IonToolbar>
                          <IonButtons slot="start">
                            <IonButton fill="clear" onClick={resetAndCloseModal}>
                              <IonIcon slot="start" icon={arrowBack}></IonIcon>
                            </IonButton>
                          </IonButtons>
                          <IonTitle>{t('select_timezone')}</IonTitle>  
                        </IonToolbar>
                        <IonSearchbar
                          placeholder={t('search')}
                          value={timezoneSearchText}
                          onIonInput={handleTimezoneInput}
                          debounce={250}
                        ></IonSearchbar>
                    </IonHeader>
                    <IonContent>
                      <IonList>
                        {filteredTimezones.map(tz => (
                          <IonItem key={tz.value} onClick={() => {
                            handleTimezoneChange(tz.value);
                            setShowTimezoneModal(false); // Optionally close the modal on selection
                          }}>
                            {tz.label}
                          </IonItem>
                        ))}
                      </IonList>
                    </IonContent>
                </IonModal>
                </>
              )}
            {promoDetails && <PromoDetails promoDetails={promoDetails} registrationDate={registrationDate}/>}

            <IonButton expand="block" onClick={() => setShowChangePasswordModal(true)}>{t('changePassword')}</IonButton>
            
            <IonModal isOpen={showChangePasswordModal} onDidDismiss={() => setShowChangePasswordModal(false)}>
              <IonHeader>
                <IonToolbar>
                  <IonTitle>{t('changePassword')}</IonTitle>
                </IonToolbar>
              </IonHeader>
              <IonContent>
                <IonItem>
                  <IonLabel position="stacked">{t('newPassword')}</IonLabel>
                  <IonInput
                    type="password"
                    value={newPassword}
                    onIonInput={(e) => setNewPassword(e.detail.value)}
                    debounce={100}
                  />
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">{t('confirmPassword')}</IonLabel>
                  <IonInput
                    type="password"
                    value={confirmPassword}
                    onIonInput={(e) => setConfirmPassword(e.detail.value)}
                    debounce={100}
                  />
                </IonItem>
                {passwordError && (
                  <IonText color="danger">
                    <p>{passwordError}</p>
                  </IonText>
                )}
                <IonButton expand="full" onClick={handleChangePassword}>{t('submit')}</IonButton>
                <IonButton expand="full" color="light" onClick={() => setShowChangePasswordModal(false)}>{t('cancel')}</IonButton>
              </IonContent>
            </IonModal>
            
            {showAdvancedSettings && (
              <IonButton expand="block" color="danger" onClick={() => handleDeleteAccount(user, getCookie, redirect, logout, t)}>
                {t('deleteAccount')}
              </IonButton>
            )}
            <IonItem>
              <small>{t('yourUUID')} {user.id}</small>
              <HelpPopover keyword="helpYourUUID" />
            </IonItem>
            <IonItem>
              <small>{t('yourEMail')} {user.eMail}</small>
            </IonItem>
            <IonItem>
              <small>{t('versionMainPage')} {mainPageVersion}</small>
            </IonItem>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default MainPage;
