import { updateUserSettings } from '../helpers/updateUserSettings'; // Adjust the path as necessary

const removeFromUserList = async (arrayName, userIdToRemove, user, setUser, getCookie, parseJwt) => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const labelsArrayName = `${arrayName}Labels`;
  const index = user[arrayName].indexOf(userIdToRemove);
  const updatedArray = user[arrayName].filter((_, i) => i !== index);
  const updatedLabelsArray = user[labelsArrayName].filter((_, i) => i !== index);

  const token = getCookie('jwtToken');
  if (!token) {
    window.location.href = '/';
    return;
  }

  try {
    await updateUserSettings(arrayName, updatedArray, getCookie, parseJwt);
    await updateUserSettings(labelsArrayName, updatedLabelsArray, getCookie, parseJwt);
    setUser((prevUser) => ({
      ...prevUser,
      [arrayName]: updatedArray,
      [labelsArrayName]: updatedLabelsArray,
    }));

    if (arrayName === 'userIdsToAlert') {
      await fetch(`${API_BASE_URL}/user-public/${userIdToRemove}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ id: user.id }),
      });
    }
  } catch (error) {
    console.error('Error updating settings:', error);
  }
};

export default removeFromUserList;
