import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import createLogger from './logging';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';


const { indexlog, indexerror } = createLogger('UserContext');
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const UserContext = createContext();

const detectClient = (userAgent) => {
  const ua = userAgent.toLowerCase();
  if (/iphone|ipad|ipod|android/.test(ua)) {
    return 'mobile';
  }
  return 'desktop';
};

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [pushPermission, setPushPermission] = useState(typeof Notification !== 'undefined' ? Notification.permission : 'default');
  const [isPushPermissionModalOpen, setIsPushPermissionModalOpen] = useState(pushPermission !== 'granted');
  const [clientType] = useState(detectClient(navigator.userAgent));
  const runningNative = Capacitor.isNativePlatform();

  // Push Notifications
  const checkPushPermission = useCallback(async () => {
    if (runningNative) {
      console.log("Running native");
      let permission;
      let permissionStatus = await PushNotifications.checkPermissions();
      if (permissionStatus.receive === 'prompt') {
        permissionStatus = await PushNotifications.requestPermissions();
      }
      permission = permissionStatus.receive;
      if (permission !== pushPermission) { 
        setPushPermission(permission);
      }

      if (permission !== 'granted' && !isPushPermissionModalOpen) {
        setIsPushPermissionModalOpen(true);
      } 
      else if (permission === 'granted' && isPushPermissionModalOpen) {
        setIsPushPermissionModalOpen(false);
      }
    }
    else {
      if (typeof Notification !== 'undefined') {
        const permission = Notification.permission;
        if (permission !== pushPermission) { 
          setPushPermission(permission);
        }
        if (permission !== 'granted' && !isPushPermissionModalOpen) {
          setIsPushPermissionModalOpen(true);
        } 
        else if (permission === 'granted' && isPushPermissionModalOpen) {
          setIsPushPermissionModalOpen(false);
        }
      } 
      else {
        if (pushPermission !== 'default') {
          setPushPermission('default');
        }
        if (!isPushPermissionModalOpen) {
          setIsPushPermissionModalOpen(true);
        }
      }
    }  
  }, [isPushPermissionModalOpen, pushPermission, runningNative]);

  useEffect(() => {
    // Update permission on mount in case it changes
    indexlog("Checking Push Notification Permissions on startup...")
    checkPushPermission();
    // eslint-disable-next-line 
  }, []);

  const getCookie = useCallback((name) => {
    if (runningNative) {
      return localStorage.getItem(name);
    }
    else {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
      return null;
    }  
  }, [runningNative]);

  const parseJwt = (token) => {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    } catch (e) {
      indexerror('Failed to parse JWT:', e);
      return null;
    }
  };

  const logout = async () => {
    let token;
    if (runningNative) {
      token = localStorage.getItem('jwtToken');
      localStorage.removeItem('jwtToken');
    }
    else {
      token = getCookie('jwtToken');
      document.cookie = 'jwtToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;';
    }
    if (token) {
      try {
        await fetch(`${API_BASE_URL}/token`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
      } catch (error) {
        indexerror('Logout failed:', error);
      }
    }
    setUser(null);
  };

  return (
    <UserContext.Provider value={{ 
      user, 
      setUser, 
      error, 
      setError, 
      successMessage, 
      setSuccessMessage, 
      logout, 
      getCookie, 
      parseJwt, 
      clientType, 
      pushPermission, 
      setPushPermission, 
      checkPushPermission,
      isPushPermissionModalOpen,
      setIsPushPermissionModalOpen
    }}>
      {children}
    </UserContext.Provider>
  );
};
